/*popups*/
.popup {
    background: #161616;
    width: 100%;
    max-width: 544px;
}

.popup-head .section-heading {
    margin: 0;
}

.popup-title {
    font-size: 24px;
    line-height: 28px;
    margin: 0 0 30px;
    text-align: center;
}

.popup-body {
    padding: 50px 20px 44px
}

.popup-form {
    max-width: 361px;
    margin: 0 auto;
}


.popup-button {
    margin-bottom: 10px
}

.popup-button:last-child {
    margin-bottom: 0
}

.btn_popup {
    width: 100%
}

.popup .form-control {
    background: #161616;
    color: #fff;
}

.popup .form-control:focus {
    border-color: #D4D4D4;
}


.results-popup {
    .h2-decor {
        background: transparent;
    }

    .fancybox-close-small {
        overflow: hidden;

        i {
            transition: transform .5s ease;
            transform-origin: 50% 50%;
            display: inline-block;
            vertical-align: top
        }
    }

    .fancybox-close-small:hover {
        color: #fff;

        i {
            transform: rotate(360deg);
        }
    }
}

.popup-results__text {
    padding: 6px 5%;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
}

.wins-details {
    max-width: 1120px;

    .popup-body {
        padding: 0
    }
}

.section-heading-table {
    background: #1D1D1D;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    padding: 14px 36px 14px 16px;
    text-transform: uppercase;

}

.table-modal {
    table-layout: auto;

    .table__row_head .table__td {
        background: #333
    }
}

@media screen and (max-width: 767px) {
    .table-modal {
        .table__row_head {
            position: static;
            opacity: 1
        }

        .table__row_head .table__td {
            min-width: 103px;
            border-bottom: 1px solid #070707;
            border-top: 1px solid #070707;
            background: #1c1c1c;
        }

        .table__row {
            border-left-width: 0
        }
    }

    .wins-details .table-wins-wrap {
        margin-right: 0
    }

    .section-heading-table {
        font-size: 16px;
        line-height: 19px;
        padding: 10px 36px 10px 8px;
        text-transform: uppercase;
    }
}

@media screen and (max-width: 510px) {
    .results-popup .h2-decor {
        padding: 12px 24px 12px;
    }

    .popup-results__text {
        padding: 0 2%;
        font-size: 18px;
        line-height: 28px;
        text-align: center;

        p:first-child {
            padding-top: 0;
        }
    }

    .popup-body {
        padding-top: 32px;
    }
}

@media screen and (min-width: 768px) {
    .table-modal {
        .table__td {
            padding: 12px 7px 12px 15px;
            display: table-cell;

            &:nth-child(1) {
                width: 9%
            }

            &:nth-child(2) {
                width: 10%;
            }

            &:nth-child(3) {
                width: 12%;
            }

            &:nth-child(4) {
                width: 12%;
            }

            &:nth-child(5) {
                width: 15%;
            }

            &:nth-child(6) {
                width: 20%;
            }

            &:nth-child(7) {
                width: 7%;
            }

            &:nth-child(8) {
                width: 6%;
            }

            &:nth-child(9) {
                width: 9%;
            }
        }
    }
}