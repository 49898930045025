.icon-arrow-circle-down:before {
	content: "\e900";
}

.icon-arrow-down:before {
	content: "\e901";
}

.icon-arrow-up:before {
	content: "\e902";
}

.icon-caret:before {
	content: "\e903";
}

.icon-close:before {
	content: "\e904";
}

.icon-date:before {
	content: "\e905";
}

.icon-email:before {
	content: "\e906";
}

.icon-key:before {
	content: "\e907";
}

.icon-lock:before {
	content: "\e908";
}

.icon-logout:before {
	content: "\e909";
}

.icon-money:before {
	content: "\e90a";
}

.icon-next:before {
	content: "\e90b";
}

.icon-password:before {
	content: "\e90c";
}

.icon-prev:before {
	content: "\e90d";
}

.icon-user:before {
	content: "\e90e";
}
