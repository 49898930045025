
/*fancybox*/

body.compensate-for-scrollbar {
    overflow: hidden;
}

.fancybox-is-hidden {
    left: -999.9rem;
    margin: 0;
    position: absolute !important;
    top: -999.9rem;
    visibility: hidden;
}

.fancybox-container {
    -webkit-backface-visibility: hidden;
    height: 100%;
    left: 0;
    outline: none;
    position: fixed;
    -webkit-tap-highlight-color: transparent;
    top: 0;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    width: 100%;
    z-index: 99992;
}

.fancybox-container * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.fancybox-outer {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
}

.fancybox-bg {
    background: #0A0A0A;
    opacity: 0;
    -webkit-transition-duration: inherit;
    -o-transition-duration: inherit;
    transition-duration: inherit;
    -webkit-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
    -o-transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
    transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}

.fancybox-is-open .fancybox-bg {
    opacity: .8;
    -webkit-transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
    -o-transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
    transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
    direction: ltr;
    opacity: 0;
    position: absolute;
    -webkit-transition: opacity .25s ease, visibility 0s ease .25s;
    -o-transition: opacity .25s ease, visibility 0s ease .25s;
    transition: opacity .25s ease, visibility 0s ease .25s;
    visibility: hidden;
    z-index: 99997;
}

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
    opacity: 1;
    -webkit-transition: opacity .25s ease 0s, visibility 0s ease 0s;
    -o-transition: opacity .25s ease 0s, visibility 0s ease 0s;
    transition: opacity .25s ease 0s, visibility 0s ease 0s;
    visibility: visible;
}

.fancybox-infobar {
    color: #ccc;
    font-size: 1.3rem;
    -webkit-font-smoothing: subpixel-antialiased;
    height: 4.4rem;
    left: 0;
    line-height: 4.4rem;
    min-width: 4.4rem;
    mix-blend-mode: difference;
    padding: 0 1rem;
    pointer-events: none;
    top: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.fancybox-toolbar {
    right: 0;
    top: 0;
}

.fancybox-stage {
    direction: ltr;
    overflow: visible;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    z-index: 99994;
}

.fancybox-is-open .fancybox-stage {
    overflow: hidden;
}

.fancybox-slide {
    -webkit-backface-visibility: hidden;
    /* Using without prefix would break IE11 */
    display: none;
    height: 100%;
    left: 0;
    outline: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 4rem;
    position: absolute;
    text-align: center;
    top: 0;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    -o-transition-property: transform, opacity;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    white-space: normal;
    width: 100%;
    z-index: 99994;
}

.fancybox-slide::before {
    content: '';
    display: inline-block;
    font-size: 0;
    height: 100%;
    vertical-align: middle;
    width: 0;
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
    display: block;
}

.fancybox-slide--image {
    overflow: hidden;
    padding: 4.4rem 0;
}

.fancybox-slide--image::before {
    display: none;
}

.fancybox-slide--html {
    padding: 0;
}

.fancybox-content {
    display: inline-block;
    margin: 0;
    max-width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 4rem;
    position: relative;
    text-align: left;
    vertical-align: middle;
}

.fancybox-slide--image .fancybox-content {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
    animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
    -webkit-backface-visibility: hidden;
    background: transparent;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    left: 0;
    max-width: none;
    overflow: visible;
    padding: 0;
    position: absolute;
    top: 0;
    -ms-transform-origin: top left;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    -o-transition-property: transform, opacity;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 99995;
}

.fancybox-can-zoomOut .fancybox-content {
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
}

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
    cursor: -webkit-grab;
    cursor: grab;
}

.fancybox-is-grabbing .fancybox-content {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.fancybox-container [data-selectable='true'] {
    cursor: text;
}

.fancybox-image,
.fancybox-spaceball {
    background: transparent;
    border: 0;
    height: 100%;
    left: 0;
    margin: 0;
    max-height: none;
    max-width: none;
    padding: 0;
    position: absolute;
    top: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
}

.fancybox-spaceball {
    z-index: 1;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
    height: 100%;
    overflow: visible;
    padding: 0;
    width: 100%;
}

.fancybox-slide--video .fancybox-content {
    background: #000;
}

.fancybox-slide--video .fancybox-close-small {
    color: #fff;
    position: fixed;
}

.fancybox-slide--map .fancybox-content {
    background: #e5e3df;
}

.fancybox-slide--iframe .fancybox-content {
    background: #fff;
}

.fancybox-video,
.fancybox-iframe {
    background: transparent;
    border: 0;
    display: block;
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
}

/* Fix iOS */
.fancybox-iframe {
    left: 0;
    position: absolute;
    top: 0;
}

.fancybox-error {
    background: #fff;
    cursor: default;
    max-width: 40rem;
    padding: 4rem;
    width: 100%;
}

.fancybox-error p {
    color: #444;
    font-size: 1.6rem;
    line-height: 2rem;
    margin: 0;
    padding: 0;
}

/* Buttons */
.fancybox-button {
    background: rgba(30, 30, 30, 0.6);
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    height: 4.4rem;
    margin: 0;
    padding: 1rem;
    position: relative;
    -webkit-transition: color .2s;
    -o-transition: color .2s;
    transition: color .2s;
    vertical-align: top;
    visibility: inherit;
    width: 4.4rem;
}

.fancybox-button--zoom {
    display: none;
}

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
    color: #ccc;
}

.fancybox-button:hover {
    color: #fff;
}

.fancybox-button:focus {
    outline: none;
}

.fancybox-button.fancybox-focus {
    outline: .1rem dotted;
}

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
    color: #888;
    cursor: default;
    outline: none;
}

/* Fix IE11 */
.fancybox-button div {
    height: 100%;
}

.fancybox-button svg {
    display: block;
    height: 2rem;
    overflow: visible;
    position: relative;
    margin: 0 auto;
    width: 2rem;
}

.fancybox-button:hover svg {
    fill: #28D88D;
}

.fancybox-button svg path {
    fill: currentColor;
    stroke-width: 0;
}

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
    display: none;
}

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
    display: none;
}

.fancybox-progress {
    background: #ff5268;
    height: .2rem;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -ms-transform: scaleX(0);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -ms-transform-origin: 0;
    -webkit-transform-origin: 0;
    transform-origin: 0;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    z-index: 99998;
}

/* Close button on the top right corner of html content */
.fancybox-close-small {
    border: 0;
    text-align: center;
    color: #fff;
    cursor: pointer;
    display: block !important;
    width: 41px;
    height: 41px;
    font-size: 1.3rem;
    background: transparent;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 401;
    font-size: 22px;
    opacity: 1;
    padding: 4px 2px 2px;
    -webkit-transition: color .3s ease-in;
    -o-transition: color .3s ease-in;
    transition: color .3s ease-in;
}

.fancybox-close-small:hover {
    color: $color-active;
}


.fancybox-slide--html .fancybox-content {
    padding: 0;
}

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
    overflow: hidden;
}

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
    display: none;
}

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
    background-clip: content-box;
    height: 10rem;
    opacity: 0;
    position: absolute;
    top: calc(50% - 5rem);
    width: 7rem;
}

.fancybox-navigation .fancybox-button div {
    padding: .9rem .7rem 0.7rem;
}

.fancybox-navigation .fancybox-button--arrow_left {
    left: 0;
    padding: 3.1rem 2.6rem 3.1rem .6rem;
}

.fancybox-navigation .fancybox-button--arrow_right {
    padding: 3.1rem .6rem 3.1rem 2.6rem;
    right: 0;
}

/* Caption */
.fancybox-caption {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.85)), color-stop(50%, rgba(0, 0, 0, 0.3)), color-stop(65%, rgba(0, 0, 0, 0.15)), color-stop(75.5%, rgba(0, 0, 0, 0.075)), color-stop(82.85%, rgba(0, 0, 0, 0.037)), color-stop(88%, rgba(0, 0, 0, 0.019)), to(rgba(0, 0, 0, 0)));
    background: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, rgba(0, 0, 0, 0) 100%);
    bottom: 0;
    color: #eee;
    font-size: 1.4rem;
    font-weight: 400;
    left: 0;
    line-height: 1.5;
    padding: 7.5rem 4.4rem 2.5rem 4.4rem;
    pointer-events: none;
    right: 0;
    text-align: center;
    z-index: 99996;
}

.fancybox-slide {
    opacity: 0;
    -webkit-transition: all 0.01s ease;
    -o-transition: all 0.01s ease;
    transition: all 0.01s ease;
}

.fancybox-slide.fancybox-slide--complete {
    opacity: 1 !important;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.fancybox-caption--separate {
    margin-top: -5rem;
}

.fancybox-caption__body {
    max-height: 50vh;
    overflow: auto;
    pointer-events: all;
}

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
    color: #ccc;
    text-decoration: none;
}

.fancybox-caption a:hover {
    color: #fff;
    text-decoration: underline;
}

/* Loading indicator */
.fancybox-loading {
    -webkit-animation: fancybox-rotate 1s linear infinite;
    animation: fancybox-rotate 1s linear infinite;
    background: transparent;
    border: .4rem solid #888;
    border-bottom-color: #fff;
    border-radius: 50%;
    height: 5rem;
    left: 50%;
    margin: -2.5rem 0 0 -2.5rem;
    opacity: .7;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 5rem;
    z-index: 99999;
}

@-webkit-keyframes fancybox-rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes fancybox-rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* Transition effects */
.fancybox-animated {
    -webkit-transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
    -o-transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
    transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
    opacity: 0;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
    opacity: 1;
}

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
    opacity: 0;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
    opacity: 0;
    -ms-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
}

.fancybox-fx-rotate.fancybox-slide--next {
    opacity: 0;
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

.fancybox-fx-rotate.fancybox-slide--current {
    opacity: 1;
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
    opacity: 0;
    -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
    transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--next {
    opacity: 0;
    -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
    transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--current {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
}

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
    -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
    transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
    -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
    transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
}

/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
    .fancybox-slide {
        padding-left: .6rem;
        padding-right: .6rem;
    }

    .fancybox-slide--image {
        padding: .6rem 0;
    }

    .fancybox-slide--image .fancybox-close-small {
        background: #4e4e4e;
        color: #f2f4f6;
        height: 3.6rem;
        opacity: 1;
        padding: .6rem;
        right: 0;
        top: 0;
        width: 3.6rem;
    }

    .fancybox-caption {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }
}

/* Thumbs */
.fancybox-thumbs {
    bottom: 0;
    display: none;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding: .2rem;
    position: absolute;
    right: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 8rem;
    z-index: 99995;
    text-align: center;
}

.fancybox-thumbs-x {
    overflow-x: auto;
    overflow-y: hidden;
}

.fancybox-show-thumbs .fancybox-thumbs {
    display: block;
}

.fancybox-show-thumbs .fancybox-inner {
    bottom: 8.5rem;
}

.fancybox-thumbs__list {
    font-size: 0;
    height: 100%;
    list-style: none;
    margin: 0 auto;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    position: relative;
    white-space: nowrap;
    width: 100%;
}

.fancybox-thumbs-x .fancybox-thumbs__list {
    overflow: hidden;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
    width: .7rem;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 1rem;
    -webkit-box-shadow: inset 0 0 0.6rem rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0.6rem rgba(0, 0, 0, 0.3);
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
    background: #2a2a2a;
    border-radius: 1rem;
}

.fancybox-thumbs__list a {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: rgba(0, 0, 0, 0.1);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    float: left;
    height: 7.5rem;
    margin: .2rem;
    max-height: calc(100% - .8rem);
    max-width: calc(50% - .4rem);
    outline: none;
    overflow: hidden;
    padding: 0;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    width: 10rem;
}

.fancybox-thumbs__list a::before {
    border: 0.6rem solid #28D88D;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    z-index: 99991;
}

.fancybox-thumbs__list a:focus::before {
    opacity: .5;
}

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
    opacity: 1;
}

.fancybox-slide--html {
    min-width: 320px;
}

.fancybox-slide--video {
    -webkit-transition: -webkit-transform 2s ease-in;
    transition: -webkit-transform 2s ease-in;
    -o-transition: transform 2s ease-in;
    transition: transform 2s ease-in;
    transition: transform 2s ease-in, -webkit-transform 2s ease-in;
    display: block;
}

.fancybox-slide--video .fancybox-content {
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

.fancybox-slide--video.fancybox-slide--complete .fancybox-content {
    -webkit-transition: -webkit-transform .5s ease-in !important;
    transition: -webkit-transform .5s ease-in !important;
    -o-transition: transform .5s ease-in !important;
    transition: transform .5s ease-in !important;
    transition: transform .5s ease-in, -webkit-transform .5s ease-in !important;
}

@media screen and (max-width: 767px) {
    .fancybox-slide--html .fancybox-content {
        padding: 16px;
    }
    .fancybox-close-small {
        right: 14px;
        top: 17px;
    }
}