
footer {
	background: #0F0F0F;
	padding: 5.1rem 0 2rem;
	-webkit-box-ordinal-group: 4;
	-ms-flex-order: 3;
	order: 3;
	font-size: 1.3rem;
	z-index: 3;
	overflow: hidden;
	.wrapper {
		max-width: 1094px 
	}
}

.footer-title {
	margin-bottom: .8rem;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	text-transform: uppercase;
	color: $color2;
}

.footer-middle {
	margin-top: 15px;
	padding: 49px 0 10px;
	border-top: 3px solid #070707;
}

.footer-text {
	font-weight: 300;
	font-size: 16px;
	line-height: 19px;
	color: $color2;
}

.footer-payments {
	max-width: 1190px;
	margin-right: -3rem;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.footer-payments__item {
	display: inline-block;
	margin: 1.6rem 3rem 1.6rem 0;
}

.footer-bottom {
	margin-top: 15px;
	padding: 49px 0 10px;
	border-top: 3px solid #070707;
}

.footer-info {
	margin-right: -3rem;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.footer-info__item {
	display: inline-block;
	opacity: .3;
	margin: 1.7rem 3rem 1.7rem 0;
}
@media screen and (max-width: 1023px) {
	.footer-bottom {
	    padding: 34px 0 4px;
    }
}
@media screen and (max-width: 767px) {
	footer {
		padding: 4.1rem 0 2rem;
	}

	.footer-payments__item {
		margin: 10px 25px 3px 0;
	}

	.footer-payments {
		max-width: 101%;
		margin-right: -25rem;
	}

	.footer-payments__item img {
		max-height: 36px;
		max-width: 90px
	}

	.footer-middle {
		padding: 14px 0 9px;
	}

	.footer-bottom {
		margin-top: 1px;
		padding: 0;
	}
}

@media screen and (max-width: 374px) {
.footer-payments__item img {
    max-height: 28px;
    max-width: 83px;
}
.footer-text {
	font-size: 15px;
	line-height: 18px;
}
.footer-info__item {
    margin: 1.5rem 2rem 1.5rem 0;
}
}